import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

function LeadNamePage() {
  const { quizId } = useParams();
  const navigate = useNavigate();
  const [name, setName] = useState("");

  const handleNext = (e) => {
    e.preventDefault();

    // Store the name temporarily in localStorage
    localStorage.setItem("leadName", name);

    // Navigate to the email page
    navigate(`/quiz/${quizId}/email`);
  };

  return (
    <div className="quiz-page">
      <h2>Almost there!</h2>
      <p>What is your first name? We’d love to personalize your experience.</p>
      <form onSubmit={handleNext}>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Enter your first name"
          required
        />
        <button type="submit" className="nextButton" style={{ height: "60px", marginBottom: "40px" }}>
          Next
        </button>
      </form>
    </div>
  );
}

export default LeadNamePage;
