import { addDoc, collection, doc, increment, updateDoc } from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react"
import { FaCheck, FaGift,FaTimes, FaPlus  } from "react-icons/fa";
import { IoTimeSharp } from "react-icons/io5";
import { db } from "./firebaseConfig";
import { FaMagnifyingGlassDollar } from "react-icons/fa6";


function LandingPage() {
    const [enteredEmail, setEnteredEmail] = useState(false);

    // useEffect(() => {
    //     const incrementViews = async () => {
    //       const docRef = doc(db, 'validationStats', 'stats');
    //       await updateDoc(docRef, {
    //         seeLanding: increment(1)
    //       });
    //     };
    
    //     incrementViews();
    //   }, []);

      const emailRef = useRef(null); // Reference to the element to observe
    const [hasBeenSeen, setHasBeenSeen] = useState(false); // State to track if the element has been seen

    const handleIntersection = async (entries, observer) => {
        const entry = entries[0];
        if (entry.isIntersecting && !hasBeenSeen) {
            const docRefe = doc(db, 'validationStats', 'stats');

            try {
                // Increment the seeEmail field in Firestore
                // await updateDoc(docRefe, {
                //     seeEmail: increment(1)
                // });

                // Mark the element as seen
                setHasBeenSeen(true);

                // Disconnect the observer since we no longer need it
                observer.disconnect();

            } catch (error) {
                console.error("Error updating document: ", error);
            }
        }
    };

    useEffect(() => {
        const observer = new IntersectionObserver(handleIntersection, {
            threshold: 0.1 // Adjust as needed
        });

        // Observe the emailRef element when it becomes visible
        if (emailRef.current) {
            observer.observe(emailRef.current);
        }

        // Cleanup observer on component unmount
        return () => {
            observer.disconnect();
        };
    }, [hasBeenSeen]);

    const beforeLambda = [
        "3 hours/day to find 33 leads",
        "3 hours/day to analyze their content",
        "2 hours/day to craft custom DMs for each of them"
    ];

    const withLambda = [
        "Automatically finds 33 leads/day respecting your criteria",
        "Uses A.I. to analyze their content",
        "Uses that information to craft a personalized DM"
    ];
    const [email, setEmail] = useState('');

  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };

  const handleJoinWaitlist = async (e) => {
    e.preventDefault();
    if(email.length)
        {
            const docRef = collection(db, 'earlyAdopters');
            await addDoc(docRef, {
                email: email
            });
            const docRefe = doc(db, 'validationStats', 'stats');
          await updateDoc(docRefe, {
            submitEmail: increment(1)
          });
          setEnteredEmail(true);
        //   if (window.fbq) {
        //     console.log('Facebook Pixel is initialized, tracking CompleteRegistration event');
        //     window.fbq('track', 'CompleteRegistration');
        //   } else {
        //     console.warn('Facebook Pixel not initialized');
        //   }
        };
        
  }

  const scrollToPlans = async (btnIndex) => {
    emailRef.current.scrollIntoView({ behavior: 'smooth' });
    const docRefe = doc(db, 'validationStats', 'stats');
    const fieldName = `CTA${btnIndex}`;

    // Create an update object with the dynamic field name
    // const updateObject = {
    //     [fieldName]: increment(1)
    // };

    // Update the Firestore document
   // await updateDoc(docRefe, updateObject);
  };

  return (
    <div style={{height:'100vh', display:'flex', flexDirection:'column', textAlign:'center', alignItems:'center', width:'100%'}}>
      <div className="menuRow">
      <p className="logo"><span style={{fontSize:'24px'}}>λ</span> Lambda<span style={{color:'#4361ee'}}>Leads</span></p> 
            <button className="landingCTA" style={{marginRight:'15px', width:'150px'}} onClick={()=>scrollToPlans(1)}>Start Now</button>
      </div>
      <div className="heroSection">
        <p style={{fontSize:'42px', fontWeight:'bold', textAlign:'center', }}>Get 1,000 Instagram cold leads<br/>per month while you sleep</p>
        <p style={{  textAlign:'center', fontSize:'20px'}}>Our A.I. will find leads for you, analyze their content and craft a personalized DM.</p>
        <button className="landingCTA" style={{ width:'250px', fontSize:'25px', fontFamily:'Poppins', marginBottom:'20px'}} onClick={()=>scrollToPlans(2)}>Start Now</button>
      </div>
      <div style={{backgroundColor:'#edf2fb', width:'97vw'}}>
        <p style={{ textAlign:'center', fontWeight:'bold', fontSize:'30px'}}>Finding leads on Instagram was long and painful.<span style={{color:'#4361ee'}}> Until now.</span></p>
        <p style={{textAlign:'start', fontSize:'17px'}}>You are probably aware that it's extremely <b>time-consuming</b> to generate Instagram cold leads.<br/><br/>Finding leads, getting information about them and writing a relevant DM takes hours.<br/><br/>Because of that, you miss on a <b>huge potential client base</b>, and tens of thousands of dollars.<br/><br/></p>
        <div class="tiles-container">
        <div class='tile' style={{border:'1px solid #d00000', backgroundColor:'#fae0e4'}}>
        <p className='withoutWith'>Without LambdaLeads</p>
        {beforeLambda.map((comp) => (
            <div style={{display:'flex', flexDirection:'row', alignItems:'center', marginTop:'-10px'}}>
            <div style={{width:'10px'}}/>
            <IoTimeSharp size={22} color='#d00000' />
            <div style={{width:'10px'}}/>
                <p style={{fontFamily:'Poppins', fontSize:'18px', textAlign:'start'}}>{comp}</p>
            </div>
        ))}
      </div>
      <p style={{ textAlign:'center', fontWeight:'bold', fontSize:'30px', marginBottom:'-0px'}}>It doesn't have to be that way.</p>
      <p style={{textAlign:'start', fontSize:'17px'}}>The best way to generate Instagram cold leads is <b>automating it.</b><br/><br/>It saves you tons of time and money, but it needs to be done carefully.<br/><br/>That's where LambdaLeads comes in.</p>
      <p style={{color:'#ef233c', fontSize:'15px', marginBottom:'-20px'}}>
       <strong>OUR OBJECTIVE HERE IS SIMPLE...</strong>
         </p>
      <p style={{ textAlign:'center', fontWeight:'bold', fontSize:'30px'}}>Getting you so many leads, you'll need to stop taking clients.</p>
      {withLambda.map((comp) => (
        <div class='tile' style={{border:'1px solid #00bb77', backgroundColor:'#e6f2e7'}}>
             <div style={{display:'flex', flexDirection:'row', alignItems:'center', width:'100%'}}>
             <div style={{width:'10px'}}/>
             <FaMagnifyingGlassDollar  size={35} />
             <div style={{width:'10px'}}/>
                 <p style={{fontFamily:'Poppins', fontSize:'18px', textAlign:'start', fontWeight:'bold'}}>{comp}</p>
             </div>
             <p style={{fontFamily:'Poppins', fontSize:'15px', textAlign:'start', paddingLeft:'10px', marginTop:'-10px'}}>{comp.description}</p>
             </div>
         ))}
         <p style={{textAlign:'start', fontSize:'17px'}}>All you need to do is to provide simple information to the A.I.<br/><br/>Takes under 5 minutes per week.</p>

        {/* <div class='tile' style={{border:'1px solid #00bb77', backgroundColor:'#e6f2e7'}}>
        <p className='withoutWith'>With LambdaLeads</p>
        {withLambda.map((comp) => (
            <div style={{display:'flex', flexDirection:'row', alignItems:'center', marginTop:'-10px'}}>
            <div style={{width:'10px'}}/>
            <FaCheck size={19} color='#00bb77' />
            <div style={{width:'10px'}}/>
                <p style={{fontFamily:'Poppins', fontSize:'18px', textAlign:'start'}}>{comp}</p>
            </div>
        ))}
      </div> */}
        </div>
        <button className="landingCTA" style={{ width:'350px', fontSize:'25px', fontFamily:'Poppins'}} onClick={()=>scrollToPlans(3)}>GIVE ME ACCESS NOW</button>
        <div style={{height:'30px'}}/>
      </div>
      <div style={{height:'30px'}}/>
      <div style={{display:'flex', flexDirection:'column',alignItems:'center'}}>
      <p style={{ textAlign:'center', fontWeight:'bold', fontSize:'30px'}} ref={emailRef}>Join the waitlist and secure a<span style={{color:'#4361ee'}}> 70% discount.</span></p>
      <p style={{fontSize:'19px'}}>Get access in early September and pay only $29/month instead of $99/month.</p>
      <form onSubmit={handleJoinWaitlist}>
      <input style={{width:'350px', height:'40px', borderRadius:'20px'}} type="email" id="email" name="email" placeholder="Your Email" value={email}
          onChange={handleInputChange}
          required></input>
          <div style={{height:'10px'}}/>
      <button className="landingCTA" style={{ width:'250px', fontSize:'25px', fontFamily:'Poppins'}}>
        Secure Access
      </button>
      </form>
      {enteredEmail && 
      <div class='tile' style={{border:'1px solid #00bb77', backgroundColor:'#e6f2e7', width:'95%'}}>
        <p className='withoutWith'>Thanks for joining</p>
        <p>You'll receive an email with your access really soon. Look for that email!</p>
        </div>}
        
      <div style={{height:'350px'}}/>

      </div>
    </div>
  )
};

export default LandingPage;
