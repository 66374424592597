import React from "react"

function FinalPage() {
    return <div className='App'>
    <div style={{ color: 'black', height:'100%', margin:'auto', textAlign:'center'}}>
     <p className='quizTitle'>Thanks for answering!</p>
    <p className='quizDesc'>Check your inbox to get your results.<br/><br/>I also secured a few<b> surprises</b> for you, so look for that email.</p> 
  </div>
  </div>
};

export default FinalPage;
