import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { db } from "../firebaseConfig";
import { doc, getDoc, updateDoc } from "firebase/firestore";

function EmailPage() {
  const { quizId } = useParams();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(true);
  const quizAnswers = JSON.parse(localStorage.getItem("quizAnswers")) || [];
  const leadName = localStorage.getItem("leadName") || ""; // Retrieve lead's name

  useEffect(() => {
    async function fetchQuizData() {
      try {
        const quizRef = doc(db, "quizzes", quizId);
        const quizSnapshot = await getDoc(quizRef);

        if (quizSnapshot.exists()) {
          const quizData = quizSnapshot.data();
          setUserId(quizData.userId);
          setLoading(false);
        } else {
          console.error("Quiz not found!");
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching quiz data:", error);
        setLoading(false);
      }
    }

    fetchQuizData();
  }, [quizId]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const userRef = doc(db, "users", userId);
      const userSnapshot = await getDoc(userRef);

      if (!userSnapshot.exists()) {
        throw new Error("User document not found");
      }

      const userData = userSnapshot.data();
      const updatedQuizzes = userData.quizzes.map((quiz) => {
        const quizLink = quiz.link;
        const extractedQuizId = quizLink.split("/quiz/")[1];

        if (extractedQuizId === quizId) {
          const newAnswer = {
            name: leadName, // Add the name
            email,
            answers: quizAnswers,
          };

          if (!quiz.answers) {
            quiz.answers = [];
          }

          quiz.answers.push(newAnswer);
        }
        return quiz;
      });

      await updateDoc(userRef, {
        quizzes: updatedQuizzes,
      });

      localStorage.removeItem("quizAnswers");
      localStorage.removeItem("leadName");
      navigate(`/quiz/${quizId}/finalPage`);
    } catch (error) {
      console.error("Error saving answers:", error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="quiz-page">
      <h2>Almost done!</h2>
      <p>Which email address should we send your results to?</p>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
          required
        />
        <button type="submit" className="nextButton" style={{ height: "60px", marginBottom: "40px" }}>
          Submit
        </button>
      </form>
    </div>
  );
}

export default EmailPage;
