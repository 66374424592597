import React, { useState } from "react"
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import App from "./App";
import Login from "./auth/Login";
import Signup from "./auth/Signup";
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './firebaseConfig';
import LandingPage from "./LandingPage";
import Home from "./insidePages/Home";
import CreateLeadMagnet from "./insidePages/CreateLeadMagnet";
import QuizPage from "./QuizTemplate/QuizLanding";
import EmailPage from "./QuizTemplate/EmailPage";
import FinalPage from "./QuizTemplate/FinalPage";
import LeadMagnetPage from "./insidePages/LeadMagnetPage";
import LeadNamePage from "./QuizTemplate/LeadNamePage";

function RootComponent() {
  const [user, loading] = useAuthState(auth);

  if (loading) {
    // Optionally, you could return a loading spinner or placeholder here
    return <div>Loading...</div>;
  }
  return (
    <Router>
        <Routes>
        <Route path="/login" element={<Login/>} />
          <Route path="/signup" element={<Signup/>} />
          <Route path="/home" element={<Home/>}/>
          <Route path="/createLeadMagnet" element={<CreateLeadMagnet/>}/>
          <Route path="/quiz/:quizId" element={<QuizPage />} />
          <Route path="/quiz/:quizId/email" element={<EmailPage />} />
          <Route path="/quiz/:quizId/finalPage" element={<FinalPage />} />
          <Route path="/LeadMagnetPage/:quizName" element={<LeadMagnetPage />} />
          <Route path="/quiz/:quizId/leadName" element={<LeadNamePage />} />


          {/* 
          <Route path="/home" element={<Home onCampainChoose={handleCampainChoice}/>}/>
          
          <Route path="/campainStats" element={<CampainStats campain={currentCampain}/>} />
           */}
           <Route path='/landingPage' element={<LandingPage/>}/>
          <Route
            path="/"
            element={user ? <Navigate to="/home" /> : <Navigate to="/signup" />}
          />

        </Routes>
    </Router>
  )
};

export default RootComponent;
