import React from "react";

function ProgressBar({ currentQuestionIndex, totalQuestions }) {
  const progress = ((currentQuestionIndex + 1) / totalQuestions) * 100;

  return (
    <div style={{
         width: '100%', 
    backgroundColor: '#e0e0e0',
        position: "fixed",
        top: 0,
        left: 0, height: '8px', borderRadius: '4px', marginTop: '10px' }}>
      <div
        style={{
          width: `${progress}%`,
          height: '100%',
          backgroundColor: '#4caf50',
          borderRadius: '4px',
          transition: 'width 0.3s ease-in-out'
        }}
      ></div>
    </div>
  );
}

export default ProgressBar;
