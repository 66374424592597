import React from 'react';

function LinkComponent({ link }) {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(link);
    alert("Link copied to clipboard!");
  };

  const visitLink = () => {
    window.open(link, "_blank");
  };

  return (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
      <p>Lead magnet link:</p>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
      <input 
          type="text" 
          value={link} 
          readOnly 
          style={{
            padding: '8px',
            borderRadius: '4px',
            border: '1px solid #ddd',
            overflow: 'auto',
            textAlign: 'center',
          }}/>
        <button onClick={copyToClipboard} style={{ padding: '8px 12px', cursor: 'pointer' }}>
          Copy
        </button>
        <button onClick={visitLink} style={{ padding: '8px 12px', cursor: 'pointer' }}>
          Visit
        </button>
      </div>
    </div>
  );
}

export default LinkComponent;
