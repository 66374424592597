// src/components/Login.js
import React, { useEffect, useState } from 'react';
import './Auth.css';
import { FcGoogle } from 'react-icons/fc';
import { useNavigate } from 'react-router-dom';
import { signInWithCredential, signInWithPopup } from 'firebase/auth';
import { provider, auth, db } from '../firebaseConfig';
import { setDoc, doc, getFirestore, getDoc } from 'firebase/firestore';


function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate(); // Initialize useNavigate

    async function getUserData(uid) {
      const firestore = getFirestore()
      const docRef = doc(firestore, "users", uid)
      const docSnap = await getDoc(docRef)
      
      const data = docSnap.exists() ? docSnap.data() : null
    
      if (data === null || data === undefined) return null
      return data;
  }

    const handleLogin = async (e) => {
      e.preventDefault();
  
      try {
        await signInWithCredential(auth, email, password);
        navigate('/home')
      } catch (error) {
        setError(error.message);
      }
    };

    const signInWithGoogle = async () => {
        try {
          const result = await signInWithPopup(auth, provider);
          const data = await getUserData(result.user.uid)
          console.log(data);
          if (data === null){
            console.log("hollu")
            await setDoc(doc(db, "users", result.user.uid), {
              uid: result.user.uid,
              email: result.user.email,
              quizzes: []
            })
            console.log("yeee")
          }   
          navigate('/home')
        } catch (error) {
          console.error(error);
        }
      };
  return (
    <div className="auth-container">
      <h2>Login</h2>
      <form onSubmit={handleLogin}>
        <div className="input-group">
          <label>Email</label>
          <input type="email" placeholder="Enter your email" required />
        </div>
        <div className="input-group">
          <label>Password</label>
          <input type="password" placeholder="Enter your password" required />
        </div>
        <button type="submit">Login</button>
      </form>
      <p>or</p>
      <div className="googleButton" onClick={signInWithGoogle}>
    <FcGoogle size={30}/>
    <div style={{width:'10px'}} />
    <p>Continue with Google</p>
      </div>
      <p>
        Don't have an account? <a href="/signup">Sign up here</a>
      </p>
    </div>
  );
}

export default Login;
