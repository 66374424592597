import React from "react"
import { useNavigate } from 'react-router-dom';
import { PiSignOut  } from "react-icons/pi";
import { getAuth, signOut } from "firebase/auth";


function NavBar() {
    const navigate = useNavigate();
    const auth = getAuth();
 
    const handleLogout = () => {
        signOut(auth)
          .then(() => {
            console.log("User signed out successfully.");
            navigate("/")
            // Redirect to the login page or home page after logout if needed
          })
          .catch((error) => {
            console.error("Error signing out: ", error);
          });
      };

  return (
    <div className="menuRow">
        <p className="logo" onClick={()=> navigate('/home')}>Lead<span style={{color:'#4361ee'}}>Thing</span></p>
        <div className="rightButtons">
            <button className="manageSub">Manage subscription</button>
            <PiSignOut color="red" size={40} className="signOut" onClick={handleLogout}/>
        </div>
    </div>
  )
};

export default NavBar;
